import React from "react";

const OrderLoader = () => {
  return (
    <div
      class="d-flex justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <div class="spinner-border" role="status" style={{ color: "#194176" }}>
        <span class="visually-hidden">Loading...</span>
      </div>
      <p className="ms-3 mb-0">Please wait...</p>
    </div>
  );
};

export default OrderLoader;
