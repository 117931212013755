import React, { useState, useEffect } from "react";

import "./success.css";
import Fireworks from "../../../assets/images/firework.png";
import firebaseService from "../../../services/firebase.services";

import { useNavigate, useParams, useRoutes } from "react-router-dom";

const Success = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const params = useParams();

  const navigateHome = () => {
    navigate("/", { replace: true });
  };

  const confirmOrder = async () => {
    try {
      const orderId = params.orderId;
      const order = await firebaseService.getDocument(
        "upComingOrders",
        orderId
      );
      if (!order) {
        return navigateHome();
      }
      if (order.isPaid && order.amountPaid) {
        await firebaseService.addDocument("orders", order.id, order);
        await firebaseService.deleteDocument("upComingOrders", order.id);
        if (order.promoData) {
          await firebaseService.updateDocument("promos", order.promoData);
        }
      } else {
        throw new Error("failed");
      }
      setLoading(false);
      setTimeout(() => {
        navigateHome();
      }, 3000);
    } catch (err) {
      setLoading(false);
      navigate("/order-failed", { replace: true });
    }
  };

  useEffect(() => {
    confirmOrder();
  }, []);

  return (
    <div className="order-success">
      <div className="success-container">
        {!loading ? (
          <div className="d-flex flex-column gap-5">
            <div className="fireworks">
              <img src={Fireworks} />
            </div>
            <div>
              <h4 className="order-success-msg">
                Your Order Has Been Created Successfully
              </h4>
              <div className="d-flex justify-content-center">
                <button className="order-success-btn" onClick={navigateHome}>
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center p-5">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                className="order-success-btn"
                onClick={() => navigate("/")}
              >
                Go Home
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Success;
