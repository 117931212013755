import { Link } from "react-router-dom";
import React from "react";
import "./ShopCards.css";
import QuickModal from "../QuickModal/QuickModal";

const ShopCards = ({ product, onClickDetails }) => {
  return (
    <div
      class="card border-0 mb-5 rounded-0 mx-3 mx-md-2"
      onClick={() => {
        onClickDetails(product);
      }}
    >
      <div className="img_container">
        <img src={product?.images?.[0]} class="card-img-top rounded-0" />
      </div>
      <p className="card_title">{product?.title}</p>
      <div class="card-body">
        <p class="card-text mt-2 mb-0">{product?.name}</p>
        <p class="card_price mt-1 mb-2">ZK{product?.price}.00</p>
        <Link to="/cart" state={{ product: product }}>
          <button className="shop_cards_btn">Order now</button>
        </Link>
      </div>
      <QuickModal product={product} />
    </div>
  );
};

export default ShopCards;
