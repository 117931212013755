import { Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Shop from "../pages/Shop/Shop";
import Cart from "../pages/Cart/Cart";
import Success from "../pages/Order/Success";
import Failed from "../pages/Order/Failed";
import Contact from "../pages/Contact/Contact";
import Page404 from "../pages/Utility/Page404";

const publicRoutes = [
  <Route path="/" element={<Home />} />,
  <Route path="/shop" element={<Shop />} />,
  <Route path="/about" element={<About />} />,
  <Route path="/contact" element={<Contact />} />,
  <Route path="/cart" element={<Cart />} />,
  <Route path="/order-success/:orderId" element={<Success />} />,
  <Route path="/order-failed" element={<Failed />} />,
  <Route path="/home" element={<Navigate to="/" replace />} />,
  // this route should be at the end of all other routes
  <Route path="*" element={<Page404 />} />,
];

export { publicRoutes };
