import React from "react";
import { Row, Col } from "reactstrap";
import homeheader from "../../assets/images/homeheader.webp";
import homeheader2 from "../../assets/images/homeheader2.webp";
import homeheader3 from "../../assets/images/homeheader3.webp";
import "./HomeHeader.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const HomeHeader = () => {
  return (
    <Row>
      <Col md={10} xl={8} className="m-auto">
        <div className="header_container">
          <img src={homeheader} className="header_img" />
          <div className="header_text ms-5">
            <h1 className="header_title">
              Advanced <br /> Networking
            </h1>
            <Link to="/shop">
              <button className="header_btn">
                Shop now{" "}
                <span>
                  <BsArrowRight className="header_icons" />
                </span>
              </button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HomeHeader;
