import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import HomeCards from "../../../components/HomeCards/HomeCards";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import QuickModal from "../../../components/QuickModal/QuickModal";

const Bands = ({ products = [] }) => {
  const [selectProduct, setSelectProduct] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClickDetails = (product) => {
    setSelectProduct(product);
    setIsOpen(!isOpen);
  };

  const handleCloseDetails = () => {
    setSelectProduct("");
    setIsOpen(!isOpen);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const filterProdcuts = products.filter(
    (product) => product.category == "WristBand"
  );

  return (
    <div>
      {selectProduct && (
        <QuickModal
          product={selectProduct}
          toggle={toggle}
          isOpen={isOpen}
          handleCloseDetails={handleCloseDetails}
        />
      )}
      <div md={10} xl={8} className="container m-auto">
        <div className="text-end mb-4">
          <Link to="/shop" className="link" style={{ color: "#c09851" }}>
            View all{" "}
            <span>
              {" "}
              <BsArrowRight color="#c09851" />
            </span>{" "}
          </Link>
        </div>
        <div className="cards-list">
          {filterProdcuts?.length > 0 && (
            <>
              {filterProdcuts.map((product, index) =>
                index < 6 ? (
                  <>
                    <div>
                      <HomeCards
                        product={product}
                        onClickDetails={() => handleClickDetails(product)}
                      />
                    </div>
                  </>
                ) : null
              )}
            </>
          )}
        </div>
      </div>
    </div>
    // <Row>
    //   {selectProduct && (
    //     <QuickModal
    //       product={selectProduct}
    //       toggle={toggle}
    //       isOpen={isOpen}
    //       handleCloseDetails={handleCloseDetails}
    //     />
    //   )}
    //   <Col md={10} xl={8} className="m-auto">
    //     <div className="text-end mb-4">
    //       <Link to="/shop" className="link" style={{ color: "#c09851" }}>
    //         View all{" "}
    //         <span>
    //           {" "}
    //           <BsArrowRight color="#c09851" />
    //         </span>{" "}
    //       </Link>
    //     </div>
    //     <Row>
    //       {filterProdcuts.map((product) => {
    //         return (
    //           <Col xs={12} sm={10} md={6} lg={4} xl={3} className="mx-auto">
    //             <HomeCards
    //               className="mb-5"
    //               product={product}
    //               onClickDetails={() => handleClickDetails(product)}
    //             />
    //           </Col>
    //         );
    //       })}
    //     </Row>
    //   </Col>
    // </Row>
  );
};

export default Bands;
