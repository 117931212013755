import React from "react";
import AboutAccordion from "../../components/AboutAccordion/AboutAccordion";

const About = () => {
  return (
    <div>
      <AboutAccordion />
    </div>
  );
};

export default About;
