import "./App.css";
// Import Routes all
import { publicRoutes } from "./routes/routes";
import { Route, Routes } from "react-router-dom";
import Navbar from "../src/components/Navbar/Navbar";
import Footer from "../src/components/Footer/Footer";
import ScrollToTop from "../src//utils/ScrollToTop";
import { Row, Col } from "reactstrap";

function App() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>{publicRoutes.map((route) => route)}</Routes>
      <Footer />
    </>
  );
}

export default App;
