import React from "react";
import { Link } from "react-router-dom";
import "./HomeCards.css";

const HomeCards = ({ product, onClickDetails }) => {
  return (
    // <div
    //   class="home-card border mb-5"
    //   onClick={() => {
    //     onClickDetails(product);
    //   }}
    // >
    //   <div className="home-img-container">
    //     <img src={product?.images?.[0]} className="card-img-top" alt="..." />
    //   </div>
    //   <p className="home-card-title">{product?.title}</p>
    //   <div class="home-card-body">
    //     <p class="home-card-text mt-2 mb-0">{product?.name}</p>
    //     <p class="home-card-price mt-1 mb-2">ZK{product?.price}.00</p>
    //     <Link to="/cart" state={{ product: product }}>
    //       <button className="shop_cards_btn">Order now</button>
    //     </Link>
    //   </div>
    // </div>
    <div
      class="card border-0 rounded-0 mb-5"
      onClick={() => {
        onClickDetails(product);
      }}
    >
      <div className="img_container">
        <img src={product?.images?.[0]} className="card-img-top" alt="..." />
      </div>
      <div class="card-body">
        <p className="card_title">{product?.title}</p>
        <p class="card-text mt-2 mb-0">{product?.name}</p>
        <p class="card_price mt-1 mb-2">ZK{product?.price}.00</p>
        <Link to="/cart" state={{ product: product }}>
          <button className="shop_cards_btn">Order now</button>
        </Link>
      </div>
    </div>
  );
};

export default HomeCards;
