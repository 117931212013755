import React from "react";

const Loader = () => {
  return (
    <div
      class="d-flex justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <div class="spinner-border" role="status" style={{ color: "#e8b660" }}>
        <span class="visually-hidden">Loading...</span>
      </div>
      <p className="ms-3 mb-0">Loading Products...</p>
    </div>
  );
};

export default Loader;
