import React, { useState } from "react";

import "./contact.css";
import Error from "../../components/Error";
import firebaseServices from "../../services/firebase.services";

import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert } from "reactstrap";

const contactSchema = Yup.object({
  name: Yup.string().required("Name is required."),
  email: Yup.string().email().required("Email is required."),
  inquiry: Yup.object().required("Select any inquiry subject."),
  subject: Yup.string().required("Subject is required."),
  message: Yup.string().required("Message is required"),
});

const inquirySubject = [
  { value: "support", label: "Support" },
  { value: "wholesale", label: "Wholesale" },
  { value: "partnership", label: "Partnership" },
  { value: "generalInquiry", label: "General Inquiry" },
  { value: "myOrder", label: "My Order" },
  { value: "reviewsFeedback", label: "Reviews Feedback" },
  { value: "customDeviceInquiry", label: "Custom Device Inquiry" },
];

const initialValues = {
  name: "",
  email: "",
  inquiry: "",
  subject: "",
  message: "",
};

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  // alert
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: contactSchema,
    onSubmit: async (values, action) => {
      try {
        setLoading(true);
        const contact = await firebaseServices.addAnyDocument("support", {
          ...values,
          inquiry: values?.inquiry?.value,
          date: new Date().toDateString(),
          sortingDate: new Date(),
          time: new Date().toLocaleTimeString(),
        });
        setLoading(false);
        setSuccess(true);
        setVisible(true);
        setTimeout(() => {
          setSuccess(false);
          setVisible(false);
        }, 5000);
      } catch (err) {
        setLoading(false);
        setFailure(true);
      }
      setTimeout(() => {
        setFailure(false);
        setVisible(false);
      }, 5000);
      action.resetForm();
    },
  });

  return (
    <div className="contact">
      <div className="contact-container">
        <form className="contact-box" onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center">
            <div className="contact-heading">
              <h2>Contact Us</h2>
              <div className="underline-heading"></div>
            </div>
          </div>
          <div className="name-email">
            <div className="name input-field">
              <label>Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
              ></input>
              {errors.name && touched.name && <Error text={errors.name} />}
            </div>
            <div className="email input-field">
              <label>Email</label>
              <input
                type="text"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
              ></input>
              {errors.email && touched.email && <Error text={errors.email} />}
            </div>
          </div>

          <div className="inquiry input-field custom-select">
            <label>Select the Subject of Your Inquiry</label>
            <Select
              className="inquiry-select"
              name="inquiry"
              id="inquiry"
              options={inquirySubject}
              value={values.inquiry}
              getOptionLabel={(option) => (
                <div className="text-capitalize">{option.label}</div>
              )}
              onChange={(value) => setFieldValue("inquiry", value)}
              getOptionValue={(option) => option}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#9BE8E3",
                  primary: "#4AE4DD",
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#4AE4DD" : "black",
                }),
              }}
            ></Select>
            {errors.inquiry && touched.inquiry && (
              <Error text={errors.inquiry} />
            )}
          </div>
          <div className="subject input-field">
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              id="subject"
              value={values.subject}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            ></input>
            {errors.subject && touched.subject && (
              <Error text={errors.subject} />
            )}
          </div>
          <div className="message input-field">
            <label>Message</label>
            <textarea
              rows={5}
              name="message"
              id="message"
              value={values.message}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            ></textarea>
            {errors.message && touched.message && (
              <Error text={errors.message} />
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="contact-btn" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
          <div className="alert_container mt-4">
            <Alert
              color={failure ? "danger" : "success"}
              isOpen={visible}
              toggle={onDismiss}
            >
              {success
                ? "Your inquiry is sent to the support team."
                : failure
                ? "Something went wrong. Try again."
                : ""}
            </Alert>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
