import React, { useState } from "react";
import "./Footer.css";
import Logo from "../../assets/images/logo.webp";
import { BsCaretDownFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import firebaseServices from "../../services/firebase.services";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [newsLetterSuccess, setNewsLetterSuccess] = useState("");

  const onSubmitNewsLetter = async () => {
    if (!email) return;
    const contact = await firebaseServices.addAnyDocument("newsLetters", {
      email,
      joinDate: new Date().toDateString(),
      sortingDate: new Date(),
      joinTime: new Date().toLocaleTimeString(),
    });
    setNewsLetterSuccess(true);
    setEmail("");
    setTimeout(() => {
      setNewsLetterSuccess(false);
    }, 3000);
  };

  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row footer-section">
            <div className="col col-sm-12 col-md-3 col-lg-3 px-0 web-info">
              <img src={Logo} alt="Logo" />
              <p>
                Just-tap network enables you
                <br /> to share every important detail
                <br /> about you and your business
                <br /> with your network.
              </p>
            </div>
            <div className=" col-sm-12 col-md-2 col-lg-2 connect px-0 ps-md-4">
              <div className="d-flex align-items-center">
                <h6>Connect</h6>
                <BsCaretDownFill className="caret" />
              </div>
              <ul>
                <li className="connections">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089714511111"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li className="connections">
                  <a
                    href="https://www.instagram.com/justtap_zm"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div className=" col-sm-12 col-md-3 col-lg-3 connect px-0 ps-md-4">
              <div className="d-flex align-items-center">
                <h6>Quick Links</h6>
                <BsCaretDownFill className="caret" />
              </div>
              <ul>
                <Link to="/about">
                  <li className="connections">About</li>
                </Link>
                <Link to="/contact">
                  <li className="connections">Contact</li>
                </Link>
              </ul>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-4 px-0 recieve-updates">
              <p>Receive the latest updates on our Networking Services</p>
              <div className="join mt-2">
                <input
                  type="emial"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <button onClick={() => onSubmitNewsLetter()}>Join</button>
              </div>
              {newsLetterSuccess ? (
                <p className="mt-2 fw-light fst-italic">
                  Thank you for subscribing to our newsletter.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copright-content">
          <p>Copyright &copy;2023 Just Tap | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
