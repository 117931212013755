import React, { useState, useEffect } from "react";
import ShopCards from "../../components/ShopCards/ShopCards";
import Loader from "../../components/Loader";
import firebaseService from "../../services/firebase.services";
import { Row, Col } from "reactstrap";
import "./Shop.css";
import QuickModal from "../../components/QuickModal/QuickModal";

const Shop = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  // modal states
  const [selectProduct, setSelectProduct] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // modal functions
  const handleClickDetails = (product) => {
    setSelectProduct(product);
    setIsOpen(!isOpen);
  };

  const handleCloseDetails = () => {
    setSelectProduct("");
    setIsOpen(!isOpen);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      const products = await firebaseService.getDocuments("products");
      setProducts(products);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <div className="shop">
        <div className="mt-5">
          {selectProduct && (
            <QuickModal
              product={selectProduct}
              toggle={toggle}
              isOpen={isOpen}
              handleCloseDetails={handleCloseDetails}
            />
          )}
          <div className=" container m-auto">
            <div className="shop_title text-center">
              Shop Premium Networking
              <div className="underline-heading mb-4"></div>
            </div>
            {loading ? <Loader /> : ""}
            <div className="cards-list">
              {products?.length > 0 && (
                <>
                  {products?.map((product) => (
                    <>
                      <div>
                        <ShopCards
                          product={product}
                          onClickDetails={() => handleClickDetails(product)}
                        />
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    // <>
    //   <div className="shop">
    //     <Row className="mt-5">
    //       {selectProduct && (
    //         <QuickModal
    //           product={selectProduct}
    //           toggle={toggle}
    //           isOpen={isOpen}
    //           handleCloseDetails={handleCloseDetails}
    //         />
    //       )}
    //       <Col md={10} xl={8} className="m-auto">
    //         <div className="shop_title text-center">
    //           Shop Premium Networking
    //           <div className="underline-heading mb-4"></div>
    //         </div>
    //         {loading ? <Loader /> : ""}
    //         <Row>
    //           {products?.length > 0 && (
    //             <>
    //               {products?.map((product) => (
    //                 <>
    //                   <Col sm={6} md={4} xl={3}>
    //                     <ShopCards
    //                       product={product}
    //                       onClickDetails={() => handleClickDetails(product)}
    //                     />
    //                   </Col>
    //                 </>
    //               ))}
    //             </>
    //           )}
    //         </Row>
    //       </Col>
    //     </Row>
    //   </div>
    // </>
  );
};

export default Shop;
