import React from "react";

import "./lightBox.css";
import Connect1 from "../../assets/images/connect1.webp";
import Connect2 from "../../assets/images/connect2.webp";
import Connect3 from "../../assets/images/connect3.webp";

const LightBox = () => {
  return (
    <div>
      <div class="gallery-wrapper">
        <div class="image-wrapper">
          <a href="#lightbox-image-1">
            <img src={Connect1} alt="" />
          </a>
        </div>
        <div class="image-wrapper">
          <a href="#lightbox-image-2">
            <img src={Connect2} alt="" />
          </a>
        </div>
        <div class="image-wrapper">
          <a href="#lightbox-image-3">
            <img src={Connect3} alt="" />
          </a>
        </div>
      </div>

      <div class="gallery-lightboxes">
        <div class="image-lightbox" id="lightbox-image-1">
          <div class="image-lightbox-wrapper">
            <a href="#" class="close"></a>
            <a href="#lightbox-image-3" class="arrow-left"></a>
            <a href="#lightbox-image-2" class="arrow-right"></a>
            <img src={Connect1} alt="" />
          </div>
        </div>

        <div class="image-lightbox" id="lightbox-image-2">
          <div class="image-lightbox-wrapper">
            <a href="#" class="close"></a>
            <a href="#lightbox-image-1" class="arrow-left"></a>
            <a href="#lightbox-image-3" class="arrow-right"></a>
            <img src={Connect2} alt="" />
          </div>
        </div>

        <div class="image-lightbox" id="lightbox-image-3">
          <div class="image-lightbox-wrapper">
            <a href="#" class="close"></a>
            <a href="#lightbox-image-2" class="arrow-left"></a>
            <a href="#lightbox-image-1" class="arrow-right"></a>
            <img src={Connect3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightBox;
