import React, { useEffect, useState } from "react";
import "./cart.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineTag } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";
import firebaseServices from "../../services/firebase.services";
import OrderPage from "./OrderPage";
import OrderLoader from "../../components/OrderLoader";
import Select from "react-select";
import { Alert } from "reactstrap";
import { generateUniqueRandomNumber } from "../../utils/functions";
import axios from "axios";

const delivery = [
  { value: "pickup", label: "Pick Up" },
  { value: "delivery", label: "Delivery" },
];

const Cart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state;
  const [quantity, setQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(product.price);
  const [promo, setPromo] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [note, setNote] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [noteDescription, setNoteDescription] = useState("");

  // alert
  const [visible, setVisible] = useState(false);

  // create order
  // loading
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  // promo loaders
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoSuccess, setPromoSuccess] = useState(false);
  const [promoError, setPromoError] = useState(false);
  const [promoData, setpromoData] = useState("");

  const onDismiss = () => setVisible(false);

  const handleCreateOrder = async (data) => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      const uniqueId = generateUniqueRandomNumber();
      let orderPrice = productPrice;
      if (promoData) {
        orderPrice = (
          productPrice -
          (promoData?.percentage / 100) * productPrice
        ).toFixed(2);
      }
      const orderDocId = await firebaseServices.addUpcomingOrder(
        "upComingOrders",
        {
          ...data,
          promoData,
          product,
          productId: product?.id,
          quantity,
          deliveryMethod,
          note: noteDescription,
          orderDate: new Date().toDateString(),
          sortingDate: new Date(),
          orderTime: new Date().toLocaleTimeString(),
          paymentId: uniqueId,
          isPaid: false,
          status: "pending",
          orderPrice,
        }
      );
      // if (promoData) {
      //   await firebaseServices.updateDocument("promos", promoData);
      // }
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API}/check-encryption`,
        {
          paymentId: uniqueId,
          firstname: data.firstName,
          lastname: data.lastName,
          price: orderPrice,
          merchantId: orderDocId.slice(2, 10),
          email: data.email,
          phoneNo: data.phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const redirectUrl = response.data.redirectUrl;
      window.location.href = redirectUrl;
      setpromoData("");
      setLoading(false);
      setFailure(false);
    } catch (err) {
      console.log("error", err.message);
      setLoading(false);
      setFailure(true);
    }
  };

  const handlePromo = async (code) => {
    try {
      setPromoLoading(true);
      const response = await firebaseServices.getPromoDocument("promos", code);
      if (!response) {
        setPromoError(true);
      }
      if (response) {
        setPromoError(false);
        setpromoData(response);
      }
      setPromoLoading(false);
      setPromoSuccess(true);
    } catch (err) {
      console.log(err.message);
      setPromoLoading(false);
      setPromoError(true);
    }
  };

  const applyPromoHandler = () => {
    setPromoError("");
    handlePromo(discountCode);
  };
  useEffect(() => {
    const { product } = location.state || {};
    if (!product) {
      navigate("/", { replace: true });
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      {loading ? (
        <OrderLoader />
      ) : (
        <div className="row mt-5">
          <div className="col-sm-12 col-lg-8 col-md-8 cart">
            <div className="d-flex justify-content-between">
              <h3>Order Product</h3>
              <Link to="/home" preventScrollReset={true} className="link">
                <div className="d-flex align-items-center continue-browsing">
                  <p>Continue Browsing</p>
                  <BsChevronRight className="chevron-right" />
                </div>
              </Link>
            </div>
            <hr />
            <div className={productPrice > 0 ? "d-none" : "empty-cart"}>
              <p>Cart is Empty</p>
            </div>
            <div className={productPrice ? "product-info" : "d-none"}>
              <IoCloseOutline
                className="close"
                onClick={() => setProductPrice(0)}
              />
              <div>
                <div
                  className="product-img"
                  style={{
                    background: `url(${product.images?.[0]}) center `,
                    backgroundSize: "100% 100%",
                  }}
                ></div>
              </div>
              <div className="d-flex  flex-column flex-lg-row justify-content-between w-100">
                <div className="product-detail">
                  <h6>{product.name}</h6>
                  <p>ZK {product.price}.00</p>
                </div>
                <div className="quantity">
                  <p
                    className={quantity === 1 && "minimum"}
                    onClick={() => {
                      quantity > 1 && setQuantity(quantity - 1);
                      quantity > 1 &&
                        setProductPrice(productPrice - product.price);
                    }}
                  >
                    -
                  </p>
                  <p>{quantity}</p>
                  <p
                    className="maximum"
                    onClick={() => {
                      setQuantity(quantity + 1);
                      setProductPrice(product.price + product.price * quantity);
                    }}
                  >
                    +
                  </p>
                </div>
                <div className="product-total-price mt-2 mt-md-0">
                  <p>ZK {productPrice}.00 </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="promo">
              <div
                className="d-flex gap-2"
                style={{ cursor: "pointer" }}
                onClick={() => setPromo(!promo)}
              >
                <AiOutlineTag className="tag-icon" />
                <p>Enter a Promo Code</p>
              </div>

              <div className={promo ? "promo-input-field" : "d-none"}>
                <input
                  placeholder="Enter a promo code"
                  onChange={(e) => setDiscountCode(e.target.value)}
                ></input>
                {promoLoading ? (
                  <button className="promo-btn">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </button>
                ) : (
                  <button
                    className="promo-btn"
                    onClick={() => applyPromoHandler()}
                  >
                    Apply
                  </button>
                )}
              </div>

              {promoError ? (
                <p className="text-danger fs-6 fw-bold mt-1">
                  Promo code isn't valid or expired
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="add-note">
              <div
                className="d-flex gap-2"
                style={{ cursor: "pointer" }}
                onClick={() => setNote(!note)}
              >
                <CgNotes className="tag-icon" />
                <p>Add a note</p>
              </div>
              <div className={note ? "add-note-input-field" : "d-none"}>
                <textarea
                  rows="4"
                  placeholder="instructions? Special requests? Add them here."
                  onChange={(e) => setNoteDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="alert_container mt-4">
                <Alert
                  color={failure ? "danger" : "success"}
                  isOpen={visible}
                  toggle={onDismiss}
                >
                  {success
                    ? "Order placed. You will receive further instructions on your email"
                    : "Something went wrong. Try again."}
                </Alert>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 col-md-4 order-summary">
            <h4>Order Summary</h4>
            <hr />
            <div className="subtotal d-flex justify-content-between">
              <p>Subtotal</p>
              <p>ZK {productPrice}.00</p>
            </div>
            <div className="subtotal d-flex justify-content-between">
              <p>Promo discount</p>
              {promoData ? (
                <p>
                  ZK {((promoData?.percentage / 100) * productPrice).toFixed(2)}
                </p>
              ) : (
                <p>ZK 0.00</p>
              )}
            </div>
            <p className="mt-2 estimate">
              <u>Estimate-Shipping</u>
            </p>
            <div className="mt-3">
              <p className="delivery-question">
                Is this order for pickup or delivery?
              </p>
              <Select
                options={delivery}
                getOptionLabel={(option) => (
                  <div className="text-capitalize">{option.value}</div>
                )}
                getOptionValue={(option) => option}
                name="delivery"
                id="delivery"
                onChange={(e) => setDeliveryMethod(e.value)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#9BE8E3",
                    primary: "#4AE4DD",
                  },
                })}
              ></Select>
              <p className={deliveryMethod ? "d-none" : "select-shipment"}>
                Please select at least one of the shipment Method.
              </p>
              <p
                className={
                  deliveryMethod === "pickup"
                    ? "delivery-answer answer1"
                    : "d-none"
                }
              >
                Pickup at Arcades shopping mall, PeterMyLife’s Distinction
                store.
              </p>
              <p
                className={
                  deliveryMethod === "delivery"
                    ? "delivery-answer answer2"
                    : "d-none"
                }
              >
                Delivery: Our customer service representative will call you on
                the phone number you will provide to arrange for delivery.
              </p>
            </div>
            <hr />
            <div className="total">
              <p>Total</p>
              {promoData ? (
                <p>
                  ZK{" "}
                  {(
                    productPrice -
                    (promoData?.percentage / 100) * productPrice
                  ).toFixed(2)}{" "}
                </p>
              ) : (
                <p>ZK {productPrice}</p>
              )}
            </div>
            <div className="checkout-btn">
              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                disabled={deliveryMethod && productPrice ? false : true}
              >
                Checkout
              </button>
            </div>
            <div className="secure">
              <FaLock className="lock" />
              <p>Secure Checkout</p>
            </div>
          </div>
        </div>
      )}
      <OrderPage handleCreateOrder={handleCreateOrder} loading={loading} />
    </div>
  );
};

export default Cart;
