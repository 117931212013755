function generateUniqueRandomNumber() {
  const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;
  if (usedNumbers.has(randomNumber)) {
    return generateUniqueRandomNumber();
  } else {
    usedNumbers.add(randomNumber);
    return randomNumber.toString();
  }
}
const usedNumbers = new Set();

export { generateUniqueRandomNumber };
