import React, { useState, useEffect } from "react";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import Loader from "../../components/Loader";
import firebaseService from "../../services/firebase.services";
import HomeCards from "../../components/HomeCards/HomeCards";
import Bands from "./Components/Bands";
import PhoneTag from "./Components/PhoneTag";
import Cards from "./Components/Cards";
import "./Home.css";
import LightBox from "../../components/LightBox/LightBox";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    try {
      setLoading(true);
      const products = await firebaseService.getDocuments("products");
      setProducts(products);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <HomeHeader />
      <div className="my-5 mx-3">
        <p className="section_title mb-1">
          Discover the look that your team <br /> deserves & Get connected.
        </p>
        <div className="underline-heading mb-3"></div>
        <div className="sections_cards">
          <Cards products={products} />
        </div>
      </div>
      <div className="my-5 mx-3">
        <p className="section_title mb-1">Just-tap, the magic in your wrist</p>
        <div className="underline-heading mb-3"></div>
        <div className="sections_cards">
          <PhoneTag products={products} />
        </div>
      </div>
      <div className="my-5 mx-3">
        <p className="section_title mb-1">Just-tap, the magic on your phone</p>
        <div className="underline-heading mb-3"></div>
        <div className="sections_cards">
          <Bands products={products} />
        </div>
      </div>
      <div className="my-5">
        <p className="section_title mb-1">Want to stay connected? Just-tap</p>
        <div className="underline-heading mb-3"></div>
        <LightBox />
      </div>
    </div>
  );
};

export default Home;
