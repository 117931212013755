import React, { useState } from "react";

import Cancel from "../../../assets/images/cancel.png";

import { useNavigate } from "react-router-dom";

const Failed = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="order-success">
      <div className="success-container">
        {!loading ? (
          <div className="d-flex flex-column gap-5">
            <div className="fireworks">
              <img src={Cancel} />
            </div>
            <div>
              <h4 className="order-success-msg">
                Sorry Your Purchase Could Not Be Completed
              </h4>
              <div className="d-flex justify-content-center">
                <button
                  className="order-success-btn"
                  onClick={() => navigate("/home")}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center p-5">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                className="order-success-btn"
                onClick={() => navigate("/home")}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Failed;
