import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPLICATION_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const adminEmail = "Orders.justtap@gmail.com";

const firebaseService = {
  addUpcomingOrder: async (collectionName, data) => {
    try {
      const docRef = doc(collection(db, collectionName));
      const docId = docRef.id;
      const docData = {
        ...data,
        id: docId,
        orderId: docRef?.id?.slice(2, 10),
      };
      await setDoc(docRef, docData);
      return docId;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  addDocument: async (collectionName, id, data) => {
    try {
      const docRef = doc(db, collectionName, id);
      const docData = data;
      await setDoc(docRef, docData);
      // // send email with order details
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let Data = {
        service_id: process.env.REACT_APP_SERVICE_ID,
        template_id: "template_re61ve6",
        user_id: process.env.REACT_APP_USER_ID,
        template_params: {
          name: data?.firstName,
          to_email: data.email,
          profileLink: `https://profile.justtap.us`,
          orderId: docRef?.id?.slice(2, 10),
        },
      };
      if (
        docData.productId == "MkyMvhgl24vhRDwu6zlu" ||
        docData.productId == "VaKavMidscKQ43RUieIa"
      ) {
        Data = {
          service_id: process.env.REACT_APP_SERVICE_ID,
          template_id: "template_xsmr798",
          user_id: process.env.REACT_APP_USER_ID,
          template_params: {
            to_email: data.email,
            orderId: docRef?.id?.slice(2, 10),
          },
        };
      }
      var newData = JSON.stringify(Data);
      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        newData,
        config
      );
      // send email to admin
      var adminMailData = {
        service_id: process.env.REACT_APP_SERVICE_ID,
        template_id: "template_k8jr8w8",
        user_id: process.env.REACT_APP_USER_ID,
        template_params: {
          to_email: adminEmail,
          orderId: docRef?.id?.slice(2, 10),
        },
      };
      var newAdminMainData = JSON.stringify(adminMailData);

      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        newAdminMainData,
        config
      );
      return;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  addAnyDocument: async (collectionName, data) => {
    try {
      const docRef = doc(collection(db, collectionName));
      const docData = {
        ...data,
        id: docRef.id,
      };
      return await setDoc(docRef, docData);
    } catch (err) {
      throw new Error(err.message);
    }
  },
  getDocument: async (collectionName, id) => {
    try {
      const docRef = doc(db, collectionName, id);
      const docRes = await getDoc(docRef);
      return docRes.data();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  getDocuments: async (collectionName) => {
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return documents;
    } catch (err) {
      console.log(err.message);
      throw new Error(err.message);
    }
  },
  getPromoDocument: async (collectionName, promo) => {
    try {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where("code", "==", promo));
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map((doc) => doc.data());
      const promoDoc = documents.shift();
      if (promoDoc.timesUsed >= promoDoc.totalLimit) {
        throw new Error("Invalid promo");
      }
      return promoDoc;
    } catch (err) {
      throw new Error(err.message);
    }
  },
  updateDocument: async (collectionName, data) => {
    try {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where("id", "==", data.id));
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map((doc) => doc.data());
      const latestData = documents.shift();
      const newData = {
        timesUsed: latestData?.timesUsed + 1,
      };
      const docRef = doc(db, collectionName, data.id);
      return await updateDoc(docRef, newData);
      // update promo document
    } catch (err) {
      console.log(err.message);
      throw new Error(err.message);
    }
  },
  deleteDocument: async (collectionName, id) => {
    try {
      const docRef = doc(db, collectionName, id);
      return await deleteDoc(docRef);
    } catch (err) {
      throw new Error(err.message);
    }
  },
};

export default firebaseService;
