import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./quickModal.css";

import { IoCloseOutline } from "react-icons/io5";

const QuickModal = ({ product, toggle, isOpen, handleCloseDetails }) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalBody>
          <div className="quick-wrapper">
            <IoCloseOutline
              className="quick-close-icon"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseDetails}
            />
            <div className="quick-product-img">
              <img
                src={product?.images[0]}
                style={{
                  objectFit: "contain",
                  maxHeight: "350px",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div className="quick-product-info">
              <div className="mt-4">
                <p className="fw-bold">{product?.name}</p>
                <p className="fw-bold">ZK {product?.price}.00</p>
                <p className="quick-description mt-3">{product?.description}</p>
              </div>
              <Link to="/cart" state={{ product: product }}>
                <button className="quick-modal-cart-btn">Order Now</button>
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default QuickModal;
