import React from "react";

function Error({ text }) {
  return (
    <div className="p-1" style={{ fontSize: "12px", color: "red" }}>
      {text}
    </div>
  );
}

export default Error;
