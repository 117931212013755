import React, { useRef } from "react";
import "./Navbar.css";
import logo from "../../assets/images/logo.webp";
import Bannar from "../../assets/images/navbar-bannar.png";
import { NavLink } from "react-router-dom";
import { Offcanvas } from "bootstrap";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";

const Navbar = () => {
  const offcanvasRef = useRef(null);
  const handleLinkClick = () => {
    const offcanvas = Offcanvas.getInstance(offcanvasRef.current);
    offcanvas.hide();
  };

  return (
    <div className="nav_bar">
      <div className="d-flex justify-content-between align-items-center mt-3 mx-md-5 px-md-5">
        <div className="logo ms-5">
          <NavLink to="/">
            {" "}
            <img src={logo} className="logo_img" />
          </NavLink>
        </div>
        <div className="d-none d-lg-block">
          <div className="d-flex justify-content-evenly ">
            <NavLink
              to="/"
              preventScrollReset={true}
              className="link hover-underline"
            >
              Home
            </NavLink>
            <NavLink
              to="/shop"
              preventScrollReset={true}
              className="link hover-underline"
            >
              Shop
            </NavLink>
            <NavLink
              to="/about"
              preventScrollReset={true}
              className="link hover-underline"
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              preventScrollReset={true}
              className="link hover-underline me-5"
            >
              Contact
            </NavLink>
          </div>
        </div>
        <RxHamburgerMenu
          size={30}
          className="d-block d-lg-none ham_burger_menu me-5"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
          ref={offcanvasRef}
        />
      </div>
      <div
        class="offcanvas offcanvas-end"
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
        data-bs-backdrop="true"
        collapseOnSelect
        data-keyboard="true"
        style={{ backgroundColor: "#000000" }}
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel"></h5>
          <RxCross2
            class="text-reset"
            size={25}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#c09851", cursor: "pointer" }}
          />
        </div>
        <div class="offcanvas-body">
          <div className="d-flex flex-column">
            <NavLink to="/" preventScrollReset={true} className="link mt-4">
              <p data-bs-dismiss="offcanvas">Home</p>
            </NavLink>
            <NavLink
              to="/shop"
              preventScrollReset={true}
              className="link mt-4"
              data-bs-dismiss="offcanvas"
            >
              <p data-bs-dismiss="offcanvas">Shop</p>
            </NavLink>
            <NavLink
              to="/about"
              preventScrollReset={true}
              className="link mt-4"
            >
              <p data-bs-dismiss="offcanvas">About Us</p>
            </NavLink>
            <NavLink
              to="/contact"
              preventScrollReset={true}
              className="link mt-4"
              data-bs-dismiss="offcanvas"
            >
              <p data-bs-dismiss="offcanvas">Contact</p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
