import React, { useState } from "react";

import "./AboutAccordion.css";

import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsLink45Deg } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const AboutAccordion = () => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div className="py-5 about">
      <div className="container">
        <h2 className="about_title mb-1">
          Get to know more on how Just-tap works
        </h2>
        <div className="underline-heading mb-4"></div>
        <div class="m-4">
          <div className="row about-section">
            <div className="col-12  m-auto ">
              <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    <strong>How does Just-tap work?</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <p>
                      A just-tap device allows you to seamlessly transfer all of
                      your information on your Just-tap Profile with a single
                      tap onto someone else's smartphone. Your Just-tap Profile
                      is fully customizable -Including your contact information,
                      social media links, payment info, and more- and enables
                      you to share every important detail about you and your
                      business with your network. The best part is that nothing
                      other than your justtap device is required to start
                      connecting: no apps, no gimmicks, no subscriptions! There
                      are three types of Just-tap devices: Just-tap cards,
                      Just-tap wristbands, and Just-tape phone tags. To put it
                      simply: your Just-tap device is the catalyst to building
                      your network.
                    </p>
                    <div className="d-flex gap-3 ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100089714511111"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                      <a>
                        <BsTwitter />
                      </a>
                      <a>
                        <BsLinkedin />
                      </a>
                      <a
                        href="https://www.instagram.com/justtap_zm"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="2">
                    <strong>
                      Does the other person need a Just-tap device to connect?
                    </strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <p>
                      No! Your Just-tap device is specifically designed to be
                      used on its own. Once setup, your Just-tap device will be
                      used to transfer your Just-tap Profile. With a single tap,
                      a link to vour Just-tap Profile will pop up as a
                      notification on the other person's phone. That link will
                      open directly in their phone's browser. Then, they will be
                      able save the contact directly to their phone. Remember:
                      no app is needed. We believe that hassle free contact
                      sharing provides the best networking experience possible.
                      No headaches, no add-ons, no troubles. Your just tap
                      device is all that you'll need to start connecting today.
                    </p>
                    <div className="d-flex gap-3 ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100089714511111"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                      <a>
                        <BsTwitter />
                      </a>
                      <a>
                        <BsLinkedin />
                      </a>
                      <a
                        href="https://www.instagram.com/justtap_zm"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">
                    <strong>
                      Do I need to install an app to use my Just-tap device?
                    </strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <p>
                      No! There is no app needed to get your Just-tap device up
                      and running. In fact, no app is needed to do anything with
                      your Just-tap device! Share your contact info and your
                      Just-tap Profile without the middle man. Everything works
                      through your phones browser, streamlining the time for
                      setup and allowing you to share your contact seamlessly
                      with any phone!
                    </p>
                    <div className="d-flex gap-3 ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100089714511111"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                      <a>
                        <BsTwitter />
                      </a>
                      <a>
                        <BsLinkedin />
                      </a>
                      <a
                        href="https://www.instagram.com/justtap_zm"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="4">
                    <strong>Is there a monthly subscription fee?</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <p>
                      No! Unlike your favorite streaming services we here at
                      Just-tap don't play hard to get. A one-time purchase of a
                      Just-tap device is all it takes to start a life full of
                      connecting through your Just-tap Profile!
                    </p>
                    <div className="d-flex gap-3 ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100089714511111"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                      <a>
                        <BsTwitter />
                      </a>
                      <a>
                        <BsLinkedin />
                      </a>
                      <a
                        href="https://www.instagram.com/justtap_zm"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">
                    <strong>How fast is delivery?</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="5">
                    <p>
                      Our distribution warehouse Is located in Lusaka, so you
                      can expect your Just-tap device to arrive within 1-2
                      business days depending on your location and order size.
                    </p>
                    <div className="d-flex gap-3 ">
                      <a
                        href="https://www.facebook.com/profile.php?id=100089714511111"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                      <a>
                        <BsTwitter />
                      </a>
                      <a>
                        <BsLinkedin />
                      </a>
                      <a
                        href="https://www.instagram.com/justtap_zm"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAccordion;
