import React from "react";
import "./OrderPage.css";
import { IoCloseOutline } from "react-icons/io5";
import { Col, Input, Label, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../../components/Error";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  phone: Yup.string().required("Phone is required"),
  email: Yup.string().required("Email is required"),
});

const OrderPage = (props) => {
  // const handlePayClick = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:5002/api/check-encryption"
  //     );
  //     const { data } = response;
  //     const redirectUrl = data.redirectUrl;
  //     window.location.href = redirectUrl;
  //   } catch (err) {
  //     console.log("error", err.message);
  //   }
  // };

  return (
    <>
      <div
        className="modal fade mt-md-3 mt-5"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div className="quick-wrapper">
                <IoCloseOutline
                  className="quick-close-icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                />
                <div className="quick-product-info">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      phone: "",
                      email: "",
                    }}
                    onSubmit={(values) => {
                      props?.handleCreateOrder(values);
                    }}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      touched,
                      errors,
                    }) => {
                      return (
                        <div className="mt-5">
                          <Row>
                            <Col className="mb-3 mx-auto">
                              <Label>First Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName && touched.firstName && (
                                <Error text={errors.firstName} />
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mb-3 mx-auto">
                              <Label>Last Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-seats-input2"
                                name="lastName"
                                min={1}
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName && touched.lastName && (
                                <Error text={errors.lastName} />
                              )}
                            </Col>
                          </Row>
                          <Row></Row>
                          <Row>
                            <Col className="mb-3 mx-auto">
                              <Label>Phone No</Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.phone && touched.phone && (
                                <Error text={errors.phone} />
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mb-3 mx-auto">
                              <Label>Email Address</Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email && touched.email && (
                                <Error text={errors.email} />
                              )}
                            </Col>
                          </Row>

                          {/* <div className="text-end">
                            <button
                              type="submit"
                              class="btn mb-5"
                              data-bs-dismiss="modal"
                              disabled={props?.loading}
                              style={{
                                backgroundColor: "#000000",
                                color: "#e8b660",
                                marginTop: "15px",
                              }}
                              onClick={handleSubmit}
                            >
                              Order
                            </button>
                          </div> */}
                          <div className="text-end">
                            <button
                              type="submit"
                              class="btn mb-5"
                              data-bs-dismiss="modal"
                              disabled={props?.loading}
                              style={{
                                backgroundColor: "#000000",
                                color: "#e8b660",
                                marginTop: "15px",
                              }}
                              onClick={handleSubmit}
                            >
                              Checkout
                            </button>
                          </div>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
